<template>
  <div>
    <!-- <a-row>
      <a-col :span="5">
        <h3 class="type">Questions</h3>
        <p class="value">{{counterCBT.counterQuestions}}</p>
      </a-col>
      <a-col :span="7">
        <h3 class="type">Multiple Choice</h3>
        <p class="value">{{counterCBT.counterMultipleChoice}}</p>
      </a-col>
      <a-col :span="4">
        <h3 class="type">Essay</h3>
        <p class="value">{{counterCBT.counterEssay}}</p>
      </a-col>
      <a-col :span="4">
        <h3 class="type">Empty</h3>
        <p
          :class="counterCBT.counterEmpty > 0 ? 'text-danger' : ''"
          class="value"
        >{{counterCBT.counterEmpty}}</p>
      </a-col>
      <a-col :span="4">
        <h3 class="type">Bobot</h3>
        <p
          :class="counterCBT.counterBobot !== 100 ? 'text-danger' : ''"
          class="value"
        >{{counterCBT.counterBobot}}</p>
      </a-col>
    </a-row>-->
    <div class="d-md-flex flex-wrap justify-content-around d-none d-md-block">
      <div>
        <h3 class="type">Questions</h3>
        <p class="value">{{counterCBT.counterQuestions}}</p>
      </div>
      <div>
        <h3 class="type">Multiple Choice</h3>
        <p class="value">{{counterCBT.counterMultipleChoice}}</p>
      </div>
      <div>
        <h3 class="type">Essay</h3>
        <p class="value">{{counterCBT.counterEssay}}</p>
      </div>
      <div>
        <h3 class="type">Empty</h3>
        <p
          :class="counterCBT.counterEmpty > 0 ? 'text-danger' : ''"
          class="value"
        >{{counterCBT.counterEmpty}}</p>
      </div>
      <div>
        <h3 class="type">Bobot</h3>
        <p
          :class="counterCBT.counterBobot !== 100 ? 'text-danger' : ''"
          class="value"
        >{{counterCBT.counterBobot}}</p>
      </div>
    </div>
    <div class="d-flex flex-column d-sm-block d-md-none">
      <div class="d-flex align-items-center justify-content-between flex-row">
        <div>
          <h3 class="type">Questions</h3>
          <p class="value">{{counterCBT.counterQuestions}}</p>
        </div>
        <div>
          <h3 class="type">Empty</h3>
          <p
            :class="counterCBT.counterEmpty > 0 ? 'text-danger' : ''"
            class="value"
          >{{counterCBT.counterEmpty}}</p>
        </div>
        <div>
          <h3 class="type">Bobot</h3>
          <p
            :class="counterCBT.counterBobot !== 100 ? 'text-danger' : ''"
            class="value"
          >{{counterCBT.counterBobot}}</p>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center flex-row mt-4">
        <div>
          <h3 class="type">Multiple Choice</h3>
          <p class="value">{{counterCBT.counterMultipleChoice}}</p>
        </div>
        <div class="ml-5">
          <h3 class="type">Essay</h3>
          <p class="value">{{counterCBT.counterEssay}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['counterCBT'],
}
</script>

<style>
</style>
